import { FormControl } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import type { ReactNode } from "react";
import React from "react";

export const FormField = (props: { children: ReactNode; caption?: string; error?: string; label?: string }) => {
  const [_css, $theme] = useStyletron();

  return (
    <FormControl
      caption={props.caption}
      error={props.error}
      label={props.label}
      overrides={{
        ControlContainer: {
          style: { flexDirection: "column", marginBottom: 0, display: "flex", gap: $theme.sizing.scale100 },
        },
        Caption: {
          style: {
            marginTop: 0,
            marginBottom: 0,
            fontSize: "13px",
            lineHeight: $theme.sizing.scale600,
            color: props.error ? $theme.colors.red500 : $theme.colors.primary500,
          },
        },
        Label: {
          style: {
            marginTop: 0,
          },
        },
      }}
    >
      {props.children}
    </FormControl>
  );
};
